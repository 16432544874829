import { skipToken } from '@reduxjs/toolkit/query'
import camelcaseKeys from 'camelcase-keys'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useGetUpgradesQuery } from '@sevenrooms/core/api'
import type { ReservationWidget } from '@sevenrooms/core/domain'
import { type Field, useForm, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import {
  getClientSelectGratuityValueFromUpgrades,
  Payment,
  type PaymentForm,
  type PaymentProps,
  useDefaultValues,
  usePaymentForm,
} from '@sevenrooms/mgr-reservation-slideout/Payment'
import { calculateTotalToReducer } from '@sevenrooms/mgr-reservation-slideout/Payment/payment-utils'
import { changePaymentForm } from '../../actions/PaymentActions'
import { useStoreSelector } from '../../selectors/useStoreSelector'
import { defaultUpgrades } from '../availability/defaults'
import { ActualPaymentLocales } from './ActualPayment.locales'
import { initPreviousFulfiledPayment, initPreviousPayment } from './utils'
import type { ValidateFieldMaps } from '../../containers/BookReservation.types'

interface PaymentFieldProps {
  validateFieldMap: ValidateFieldMaps['payment']
  isShow: boolean
}

export function PaymentField({ validateFieldMap, isShow }: PaymentFieldProps) {
  const { formatMessage } = useLocales()

  const { selectedVenue, isPreselectedTime } = useStoreSelector(state => state.bookState)
  const { categories: categoriesForm } = useStoreSelector(state => state.upgradesState)
  const { partySize, selectedTimeSlot, actual } = useStoreSelector(state => state.bookAvailabilityState)
  const bookPaymentState = useStoreSelector(state => state.bookPaymentState)
  const { paymentRule, currencyCode, currencySymbol, override, allTransactions, defaultGratuity, defaultServiceCharge } = bookPaymentState
  const isAdvancedPayment = paymentRule === 'advanced_payment'

  const { data = defaultUpgrades } = useGetUpgradesQuery(selectedVenue?.id ?? skipToken)

  const taxGroups = useMemo(
    () => camelcaseKeys(selectedVenue?.bookSettings.taxGroups ?? [], { deep: true }),
    [selectedVenue?.bookSettings.taxGroups]
  )

  const oldPartySize = actual?.max_guests ?? null
  const oldValues = useMemo(
    () => (allTransactions ? initPreviousPayment({ allTransactions, upgrades: data, taxGroups, oldPartySize }) : undefined),
    [allTransactions, data, taxGroups, oldPartySize]
  )
  const oldFulfilledValues = useMemo(
    () => (allTransactions ? initPreviousFulfiledPayment({ allTransactions, upgrades: data, taxGroups, oldPartySize }) : undefined),
    [allTransactions, data, taxGroups, oldPartySize]
  )
  const bundledUpgrades = selectedTimeSlot?.selected_automatic_upsells

  // current client selectable gratuity charge. This ensures default values are set as expected
  const clientSelectGratuityCharge = useMemo(() => {
    // if this hasnt been marked true yet, do no calculations
    if (!bookPaymentState.clientSelectGratuity.gratuityClientSelect) {
      return null
    }

    if (bookPaymentState.cardEntryOption === 'paylink') {
      return 0
    }

    let gratuity: number | null = 0
    // check if we have already persisted this value in state, we can grab it from there
    if (bookPaymentState.clientSelectGratuity.gratuity) {
      gratuity = Number(bookPaymentState.clientSelectGratuity.gratuity)
      // check the main charge gratuity type and grab it from there if its client select
    } else if (bookPaymentState.gratuityType === 'CLIENT_GRATUITY') {
      gratuity = Number(bookPaymentState.gratuityCharge ?? 0)
      // else we can loop through the upgrades and check if any of them have it set and return the value
    } else {
      gratuity = getClientSelectGratuityValueFromUpgrades(bookPaymentState.upgrades)
    }

    return gratuity
  }, [
    bookPaymentState.cardEntryOption,
    bookPaymentState.clientSelectGratuity.gratuity,
    bookPaymentState.clientSelectGratuity.gratuityClientSelect,
    bookPaymentState.gratuityCharge,
    bookPaymentState.gratuityType,
    bookPaymentState.upgrades,
  ])

  const defaultValues = useDefaultValues({
    partySize,
    chargeDetails: bookPaymentState,
    upgrades: data,
    bundledUpgrades,
    categoriesForm,
    defaultGratuity,
    defaultServiceCharge,
    isPreselectedTime,
    oldValues,
    clientSelectGratuityCharge,
  })
  const paymentsSchema = usePaymentForm()
  const { field, trigger, setError, reset } = useForm(paymentsSchema, { defaultValues })

  const [amount] = useWatchMany(field, ['amount'])

  useEffect(() => {
    reset(defaultValues, { keepDirty: true, keepDirtyValues: true })
    // eslint-disable-next-line
  }, [defaultValues, override])

  // integration into legacy custom validation mechanism
  // eslint-disable-next-line no-param-reassign
  validateFieldMap.amount = {
    isValid: () => {
      if (amount || !isAdvancedPayment) {
        return true
      }
      setError('amount', { type: 'custom', message: formatMessage(ActualPaymentLocales.amountRequiredError) }, { shouldFocus: true })
      return formatMessage(ActualPaymentLocales.amountRequiredError)
    },
  }
  // eslint-disable-next-line no-param-reassign
  validateFieldMap.gratuity = {
    isValid: () =>
      trigger('clientSelectGratuity.gratuity', { shouldFocus: true }).then(valid =>
        valid || override ? true : formatMessage(ActualPaymentLocales.gratuityRequiredError)
      ),
  }

  return (
    <>
      {isShow && (
        <>
          <FieldGroup key="FieldGroup_TakePayment">
            <Payment
              field={field}
              oldValues={oldValues}
              oldFulfilledValues={oldFulfilledValues}
              currencyCode={currencyCode}
              currencySymbol={currencySymbol}
              override={override}
              taxGroups={taxGroups}
              selectable={!isAdvancedPayment || (override && !selectedTimeSlot?.selected_automatic_upsells?.length)}
            />
          </FieldGroup>
          <HorizSeparator />
        </>
      )}
      <ReduxDispatcher field={field} oldValues={oldValues} taxGroups={taxGroups} override={override} />
    </>
  )
}

// integration into legacy redux. Separate component to reduce renders. When redux would be removed from res slideout it will not be needed
function ReduxDispatcher({
  field,
  oldValues,
  taxGroups,
  override,
}: {
  field: Field<PaymentForm>
  oldValues?: PaymentProps['oldValues']
  taxGroups: ReservationWidget.TaxGroup[]
  override: boolean
}) {
  const dispatch = useDispatch()
  const { selectedTimeSlot } = useStoreSelector(state => state.bookAvailabilityState)
  const bundledUpgrades = selectedTimeSlot?.selected_automatic_upsells ?? []

  const [amount, charges, categories, categoriesBundled, clientSelectGratuity] = useWatchMany(field, [
    'amount',
    'charges',
    'categories',
    'categoriesBundled',
    'clientSelectGratuity',
  ])

  useEffect(() => {
    if (charges) {
      const values = { amount, charges, categories, categoriesBundled, clientSelectGratuity }
      dispatch(changePaymentForm(calculateTotalToReducer(values, taxGroups, override, bundledUpgrades, oldValues)))
    }
    // eslint-disable-next-line
  }, [amount, charges, categories, categoriesBundled, clientSelectGratuity])

  return null
}

const HorizSeparator = styled.div`
  border-bottom: 1px dashed ${props => props.theme.lightGrey};
`

const FieldGroup = styled.div`
  margin: 15px 12px;
  ${props => props.theme.clearFix};
`
