import money from 'money-math'
import styled from 'styled-components'
import { PulsatingDots } from 'mgr/layout/StyledComponentUtils'
import { useLocales } from '@sevenrooms/core/locales'
import { useChangedReservationInfoWithPaylinkFlow } from '../../components/payment/hooks'
import { useStoreSelector } from '../../selectors/useStoreSelector'
import { submitButtonLocales } from './SubmitButton.locales'

interface SubmitButtonProps {
  onSubmit: () => void
  isLoading: boolean
}
export function SubmitButton({ onSubmit, isLoading }: SubmitButtonProps) {
  const { formatMessage } = useLocales()
  const [isEditWithOutstandingPaylinkFlow] = useChangedReservationInfoWithPaylinkFlow()
  const { isEditMode } = useStoreSelector(state => state.bookDetailsState)
  const { cardEntryOption, chargeAmount, chargeTotal, internalUpsellsEnabled, takePaymentOrSave, outstandingAddFlowTransaction } =
    useStoreSelector(state => state.bookPaymentState)
  const { isDirty: isUpgradesChanged } = useStoreSelector(state => state.upgradesState)

  const amount = internalUpsellsEnabled ? chargeTotal : chargeAmount

  const hasOutstandingSaveCardPaylink = outstandingAddFlowTransaction && outstandingAddFlowTransaction.amount === 0

  const isSendPaylink =
    !isEditWithOutstandingPaylinkFlow &&
    cardEntryOption === 'paylink' &&
    ((takePaymentOrSave === 'take' && money.isPositive(amount ?? '')) ||
      (takePaymentOrSave === 'save' && !hasOutstandingSaveCardPaylink) ||
      (isUpgradesChanged && money.isPositive(amount ?? '')))

  return (
    <BookButton data-test="sr-button-book" onClick={onSubmit} disabled={isLoading}>
      {(() => {
        if (isLoading) {
          return <PulsatingDots />
        } else if (isEditMode) {
          return isSendPaylink
            ? formatMessage(submitButtonLocales.saveReservationWithPaylink)
            : formatMessage(submitButtonLocales.saveReservation)
        }
        return isSendPaylink
          ? formatMessage(submitButtonLocales.bookReservationWithPaylink)
          : formatMessage(submitButtonLocales.bookReservation)
      })()}
    </BookButton>
  )
}

const BookButton = styled.button`
  ${props => props.theme.primaryButton};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  height: 54px;
  line-height: 54px;
  width: 100%;
`
