import _ from 'lodash'
import React, { useMemo, useState, useCallback } from 'react'
import MomentPropTypes from 'react-moment-proptypes'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { AccessRulesTimeslots } from 'mgr/actualslideout/components/availability/AccessRulesTimeslots'
import { MultiVenueAccessRulesTimeslots } from 'mgr/actualslideout/components/availability/MultiVenueAccessRulesTimeslots'
import AvailabilityDayEvents from 'mgr/actualslideout/components/availability/AvailabilityDayEvents'
import AvailabilityTimeSlot from 'mgr/actualslideout/components/availability/AvailabilityTimeSlot'
import { ClearFix, LoadingSpinner } from 'mgr/layout/StyledComponentUtils'
import PaginationArrows from 'mgr/lib/components/PaginationArrows'
import * as MomentUtils from 'mgr/lib/utils/MomentUtils'
import { MIN_SORT_ORDER, MAX_SORT_ORDER } from 'svr/common/TimeUtil'
import { VmsIcons, StyledIcons } from 'svr/common/VmsIcons'
import { useMultiVenueAvailabilityQueryParamsSelector } from '../../../components/availability/useMultiVenueAvailabilityTimeslotsRequest'
import { hasChangedCriteriaFromLastSave } from '../../../components/availability/helpers'
import { KeepOrRemoveARPopup } from './KeepOrRemoveARPopup'

const DateHeader = styled.div`
  color: ${props => props.theme.navigationDark};
  font-size: 16px;
  font-weight: 500;
  margin: 10px;
  text-transform: uppercase;
  display: inline-block;
`

const TopRightPanel = styled.div`
  float: right;
  margin: 10px 10px 0 0;
`

const TimeResultsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0 -5px;
`

const ResultColumn = styled.div`
  max-width: 500px;
  min-width: 99px;
  width: 500px; // Make all columns equal width (flex will shrink them)
  flex-grow: 1;
  flex-shrink: 1;
`

const EmptyState = styled.div`
  font-size: ${props => (props.isCondensedFormat ? '18px' : '24px')};
  padding: 34px 0;
  color: ${props => props.theme.darkGrey};
  text-align: center;
`

const ErrorState = styled.div`
  font-size: ${props => (props.isCondensedFormat ? '18px' : '24px')};
  padding: 34px 0;
  color: ${props => props.theme.error};
  text-align: center;
`

const ResultColumnHeaderRemoveIcon = styled(StyledIcons.XXS)`
  border-radius: 50%;
  background: ${props => props.theme.lightGrey};
  color: ${props => props.theme.white};
  font-weight: 600;
  padding: 3px;
  text-align: center;
  position: absolute;
  right: 5px;
  top: 0;
  cursor: pointer;
  display: none;
  :hover {
    background: ${props => props.theme.darkGrey};
  }
`

const ResultColumnHeader = styled.div`
  margin-top: 15px;
  position: relative;
  ${props => props.theme.clearFix}
  :hover ${ResultColumnHeaderRemoveIcon} {
    display: block;
  }
`

const ResultColumnHeaderTitle = styled.div`
  padding-right: ${props => (props.hasIconOffset ? '24px' : 0)};
  min-height: 19px;
  line-height: 19px;
  color: ${props => props.theme.navigationDark};
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
  ${props => props.theme.fontWeight300} ${props => props.theme.ellipsis};
`

const TimeSlotSpacer = styled.div`
  height: 44px;
  margin: 3px 5px;
  border-radius: 4px;
  background: ${props => props.theme.lightGrey};
  opacity: 0.2;
`

const ARSection = styled.div`
  min-width: 518px;
  margin: 3px 0 0 -5px;
`

const getMinMaxOrders = (venues, availabilityByVenue) => {
  const allAvailableTimes = _.compact(
    venues.map(searchVenue => {
      const { availableTimes, isTimesLoading, isTimesLoadError } = availabilityByVenue[searchVenue.id] || {}
      return isTimesLoading || isTimesLoadError || _.isEmpty(availableTimes) ? null : availableTimes
    })
  )
  if (_.isEmpty(allAvailableTimes)) {
    return [0, 0]
  }
  const [minOrder, maxOrder] = _.reduce(
    allAvailableTimes,
    ([minOrder, maxOrder], availableTimes) => [
      Math.min(minOrder, _.first(availableTimes).sort_order),
      Math.max(maxOrder, _.last(availableTimes).sort_order),
    ],
    [MAX_SORT_ORDER, MIN_SORT_ORDER]
  )
  return [minOrder, maxOrder]
}

const convertTimeSlotsToOrderMap = availableTimes =>
  _.reduce(availableTimes, (result, timeSlot) => _.assign(result, { [timeSlot.sort_order]: timeSlot }), {})

const getRangeForSingleVenue = (availabilityByVenue, singleVenue) => {
  const availableTimes = availabilityByVenue[singleVenue.id]?.availableTimes || []
  return availableTimes.map(time => time.sort_order)
}

function AvailabilityResults({
  searchVenues,
  searchResultsPage,
  resultsPerPage,
  date,
  partySize,
  duration,
  previousDate,
  previousPartySize,
  previousDuration,
  actions,
  availabilityByVenue,
  dailyEventsByVenueDate,
  selectedTimeSlot,
  previousSelectedTimeSlot,
  internalArBookingEnabled,
  showAccessRules,
  isEditReservation,
  shiftChargeDataAction,
  reservationRequestPartySize,
  reservationRequestDateLabel,
  reservationRequestTimeLabel,
  reservationRequestOfferName,
  isRequestAvailable,
}) {
  const [keepOrRemoveTimeslot, setKeepOrRemoveTimeslot] = useState(false) // false | timeslot
  const [selectedVenue, setSelectedVenue] = useState(searchVenues[0])
  const availabilityTimeslotsRequest = useMultiVenueAvailabilityQueryParamsSelector()
  const multiVenueInternalARBookingEnabled = window.globalInit.venueSettings.multi_venue_internal_ar_booking_enabled
  const showKeepOrRemoveAccessRule = window.globalInit.venueSettings.show_keep_or_remove_access_rule

  const isShowARSection = internalArBookingEnabled && showAccessRules
  const isCondensedFormat = searchVenues.length > 3
  const multiDateSegCtrlOptions = [
    { name: 'day', value: 'single' },
    { name: '+/- 1 day', value: '3-day' },
    { name: '+/- 3 days', value: '7-day' },
  ]
  const defaultMultiDateOption = 'single'
  const isMultiVenue = searchVenues.length > 1
  const singleVenue = searchVenues.length === 1 ? searchVenues[0] : null
  const singleVenueDailyEvents = singleVenue && (dailyEventsByVenueDate[singleVenue.id] || {})[date]
  const venuesToShow = _.slice(searchVenues, searchResultsPage * resultsPerPage, (1 + searchResultsPage) * resultsPerPage)
  const [minOrder, maxOrder] = getMinMaxOrders(venuesToShow, availabilityByVenue)
  const timeSlotRange = _.range(minOrder, maxOrder + 1)
  let uniqueKeyCounter = 0

  const onSelectShiftTimeslot = useCallback(
    (timeSlot, venue, isVenueChanged = false) => {
      if (internalArBookingEnabled && selectedTimeSlot?.access_persistent_id && !timeSlot.access_persistent_id && !isVenueChanged) {
        setSelectedVenue(venue)
        if (isEditReservation && showKeepOrRemoveAccessRule) {
          setKeepOrRemoveTimeslot(timeSlot)
        } else {
          actions.changeSelectedTimeSlot(venue, timeSlot)
          actions.restoreShiftCharges(shiftChargeDataAction)
        }
      } else {
        actions.changeSelectedTimeSlot(venue, timeSlot)
      }
    },
    [
      internalArBookingEnabled,
      isEditReservation,
      selectedTimeSlot,
      setKeepOrRemoveTimeslot,
      actions,
      shiftChargeDataAction,
      showKeepOrRemoveAccessRule,
    ]
  )

  const renderAvailableTimes = (venue, availableTimes) => {
    const { showSeatingAreaCovers } = venue.bookSettings
    if (_.isNil(availableTimes)) {
      return null
    }
    if (_.isEmpty(availableTimes)) {
      return <EmptyState {...{ isCondensedFormat }}>No Results</EmptyState>
    }
    const availableTimesByOrder = convertTimeSlotsToOrderMap(availableTimes)
    return timeSlotRange.map(order => {
      const timeSlot = availableTimesByOrder[order]
      if (_.isNil(timeSlot)) {
        // eslint-disable-next-line no-plusplus
        return isMultiVenue && <TimeSlotSpacer key={`TimeSlotSpacer${uniqueKeyCounter++}`} />
      }
      const isSelected = Boolean(
        selectedTimeSlot &&
          !selectedTimeSlot.access_persistent_id &&
          selectedTimeSlot.venue_id === timeSlot.venue_id &&
          selectedTimeSlot.sort_order === timeSlot.sort_order
      )
      return (
        <AvailabilityTimeSlot
          key={timeSlot.time}
          {...{
            isCondensedFormat,
            timeSlot,
            partySize,
            showSeatingAreaCoversVar: showSeatingAreaCovers,
            venue,
            isSelected,
            isMultiVenue,
          }}
          onSelect={() => onSelectShiftTimeslot(timeSlot, venue)}
        />
      )
    })
  }

  const onRemoveAR = useCallback(() => {
    actions.changeSelectedTimeSlot(selectedVenue, keepOrRemoveTimeslot)
    actions.restoreShiftCharges(shiftChargeDataAction)
  }, [actions, selectedVenue, keepOrRemoveTimeslot, shiftChargeDataAction])

  const onKeepAR = useCallback(() => {
    const fakeTimeslot = {
      ...selectedTimeSlot,
      time: keepOrRemoveTimeslot.time,
      time_iso: keepOrRemoveTimeslot.time_iso,
      timeMoment: keepOrRemoveTimeslot.timeMoment,
      sort_order: keepOrRemoveTimeslot.sort_order,
      isAccessruleOverride: true,
    }
    actions.addMultiVenueAvailabilityTimeslot(availabilityTimeslotsRequest, selectedVenue?.id ?? -1, fakeTimeslot)
    actions.changeExternalAvailabilityTimeSlot(selectedVenue, fakeTimeslot)
    actions.changeShowAccessRules(true)
  }, [actions, selectedTimeSlot, availabilityTimeslotsRequest, keepOrRemoveTimeslot, selectedVenue])
  const onMultiDateSegCtrlChange = value => {
    svrDebug(`onMultiDateSegCtrlChange: ${value}`)
  }
  const onClickResultColumnHeaderTitle = (venue, shiftPersistentId) => {
    actions.changeSearchVenues([venue], shiftPersistentId)
  }
  const onClickResultColumnHeaderRemove = venue => {
    actions.changeSearchVenues(searchVenues.filter(v => v.id !== venue.id))
  }
  const numPages = searchVenues.length / resultsPerPage

  const hasChangedCriteria = useMemo(
    () => hasChangedCriteriaFromLastSave(partySize, duration, date, previousPartySize, previousDuration, previousDate),
    [date, duration, partySize, previousDate, previousDuration, previousPartySize]
  )

  return (
    <div>
      <ClearFix>
        <TopRightPanel>
          {numPages > 1 && <PaginationArrows page={searchResultsPage} numPages={numPages} onChange={actions.changeSearchResultsPage} />}
          {
            null /* Disabled for MVP:
          <SegmentedControl
            options={multiDateSegCtrlOptions}
            value={defaultMultiDateOption}
            width={249}
            height={26}
            onChangeHandler={this.onMultiDateSegCtrlChange}
          />
         */
          }
        </TopRightPanel>
        <DateHeader data-test="sr-label-date">{MomentUtils.formatWithoutYear(date, 'dddd, LL')}</DateHeader>
      </ClearFix>
      {singleVenueDailyEvents && (
        <AvailabilityDayEvents
          dailyEvents={singleVenueDailyEvents}
          venue={singleVenue}
          requestPartySize={reservationRequestPartySize}
          requestDateLabel={reservationRequestDateLabel}
          requestTimeLabel={reservationRequestTimeLabel}
          requestOfferName={reservationRequestOfferName}
          isRequestAvailable={isRequestAvailable}
        />
      )}
      <TimeResultsWrapper>
        {venuesToShow.map(searchVenue => {
          const { availableTimes, isTimesLoading, isTimesLoadError } = availabilityByVenue[searchVenue.id] || {}
          const dailyEvents = (dailyEventsByVenueDate[searchVenue.id] || {})[date]
          const hasIconOffset = dailyEvents && (!_.isEmpty(dailyEvents.events) || !_.isEmpty(dailyEvents.note))
          const isSelectedVenue = Boolean(selectedTimeSlot && selectedTimeSlot.venue_id === searchVenue.id)
          const firstShiftPersistentId = _.isEmpty(availableTimes) ? null : _.first(availableTimes).shift_persistent_id
          return (
            <ResultColumn key={searchVenue.id}>
              {null /* No Date columns for MVP: <ResultColumnHeader>{MomentUtils.formatWithoutYear(date, 'ddd, l')}</ResultColumnHeader> */}
              {isMultiVenue && (
                <ResultColumnHeader>
                  <AvailabilityDayEvents
                    {...{ dailyEvents }}
                    venue={searchVenue}
                    isCondensed
                    showAdditionalSidePaneWindow={isSelectedVenue}
                  />
                  <ResultColumnHeaderTitle
                    {...{ hasIconOffset }}
                    onClick={() => onClickResultColumnHeaderTitle(searchVenue, firstShiftPersistentId)}
                  >
                    {searchVenue.internalDisplayName}
                  </ResultColumnHeaderTitle>
                  <ResultColumnHeaderRemoveIcon onClick={() => onClickResultColumnHeaderRemove(searchVenue)}>
                    {VmsIcons.Close}
                  </ResultColumnHeaderRemoveIcon>
                </ResultColumnHeader>
              )}
              {!(isMultiVenue && multiVenueInternalARBookingEnabled && isShowARSection) && (
                <>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isTimesLoading ? (
                    <LoadingSpinner />
                  ) : isTimesLoadError ? (
                    <ErrorState>Load Error</ErrorState>
                  ) : (
                    renderAvailableTimes(searchVenue, availableTimes || null)
                  )}
                </>
              )}
            </ResultColumn>
          )
        })}
        {isShowARSection && singleVenue != null && (
          <ARSection>
            <AccessRulesTimeslots
              singleVenueId={singleVenue.id}
              timeSlotRange={getRangeForSingleVenue(availabilityByVenue, singleVenue)}
              selectedTimeslot={selectedTimeSlot}
              previousSelectedTimeslot={previousSelectedTimeSlot}
              hasChangedCriteriaFromLastSave={hasChangedCriteria}
              onChange={(timeslot, isPreviousTime, isDirectlySelected) =>
                actions.changeExternalAvailabilityTimeSlot(singleVenue, timeslot, false, isPreviousTime, false, isDirectlySelected)
              }
            />
          </ARSection>
        )}
        {internalArBookingEnabled && (
          <KeepOrRemoveARPopup
            selectedTimeSlot={selectedTimeSlot}
            isActive={!!keepOrRemoveTimeslot}
            setIsActive={setKeepOrRemoveTimeslot}
            onRemove={onRemoveAR}
            onKeep={onKeepAR}
          />
        )}
      </TimeResultsWrapper>

      {isMultiVenue && multiVenueInternalARBookingEnabled && isShowARSection && (
        <MultiVenueAccessRulesTimeslots
          searchVenues={venuesToShow}
          selectedTimeslot={selectedTimeSlot}
          previousSelectedTimeslot={previousSelectedTimeSlot}
          hasChangedCriteriaFromLastSave={hasChangedCriteria}
          onSelectShiftTimeslot={onSelectShiftTimeslot}
          onSelectARTimeslot={(timeslot, isPreviousTime, isDirectlySelected, venue) =>
            actions.changeExternalAvailabilityTimeSlot(venue, timeslot, false, isPreviousTime, false, isDirectlySelected)
          }
        />
      )}
    </div>
  )
}

AvailabilityResults.propTypes = {
  searchVenues: React.PropTypes.array.isRequired,
  searchResultsPage: React.PropTypes.number.isRequired,
  resultsPerPage: React.PropTypes.number.isRequired,
  date: MomentPropTypes.momentObj.isRequired,
  partySize: React.PropTypes.number.isRequired,
  seatingArea: React.PropTypes.object,
  availabilityByVenue: React.PropTypes.object.isRequired,
  dailyEventsByVenueDate: React.PropTypes.object.isRequired,
  actions: React.PropTypes.object.isRequired,
  selectedTimeSlot: React.PropTypes.object,
  internalArBookingEnabled: React.PropTypes.bool,
  showAccessRules: React.PropTypes.bool,
  isEditReservation: React.PropTypes.bool,
  reservationRequestPartySize: React.PropTypes.number,
  reservationRequestDateLabel: React.PropTypes.string,
  reservationRequestTimeLabel: React.PropTypes.string,
  reservationRequestOfferName: React.PropTypes.string,
  isRequestAvailable: React.PropTypes.bool,
}

AvailabilityResults.defaultProps = {
  actions: {},
}

const mapStateToProps = state => {
  const {
    date,
    reservationRequestPartySize,
    reservationRequestDateLabel,
    reservationRequestTimeLabel,
    reservationRequestOfferName,
    isRequestAvailable,
  } = state.bookAvailabilityState

  return {
    searchVenues: state.bookAvailabilityState.searchVenues,
    searchResultsPage: state.bookAvailabilityState.searchResultsPage,
    resultsPerPage: state.bookAvailabilityState.resultsPerPage,
    date,
    partySize: state.bookAvailabilityState.partySize,
    duration: state.bookAvailabilityState.duration,
    previousDate: state.bookAvailabilityState.previousDate,
    previousPartySize: state.bookAvailabilityState.previousPartySize,
    previousDuration: state.bookAvailabilityState.previousDuration,
    availabilityByVenue: state.bookAvailabilityState.availabilityByVenue,
    dailyEventsByVenueDate: state.bookAvailabilityState.dailyEventsByVenueDate,
    selectedTimeSlot: state.bookAvailabilityState.selectedTimeSlot,
    previousSelectedTimeSlot: state.bookAvailabilityState.previousSelectedTimeSlot,
    internalArBookingEnabled: state.bookAvailabilityState.internalArBookingEnabled,
    showAccessRules: state.bookAvailabilityState.showAccessRules,
    isEditReservation: state.bookAvailabilityState.actual !== null,
    shiftChargeDataAction: state.bookPaymentState.shiftChargeDataAction,
    reservationRequestPartySize,
    reservationRequestDateLabel,
    reservationRequestTimeLabel,
    reservationRequestOfferName,
    isRequestAvailable,
  }
}

export default connect(mapStateToProps)(AvailabilityResults)
