import priceFormatter from 'currency-formatter'
import _ from 'lodash'
import React, { useEffect } from 'react'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import * as ReviewActions from 'mgr/actualslideout/actions/ReviewActions'
import * as SlideoutActions from 'mgr/actualslideout/actions/SlideoutActions'
import * as ViewActualActions from 'mgr/actualslideout/actions/ViewActualActions'
import { ClientSubCard, SourceSubCard } from 'mgr/actualslideout/components/view/ContactCard'
import {
  DetailsHeaderSubCard,
  NoteSubCard,
  TagSubCard,
  PerksSubCard,
  PriceSubCard,
  buildCustomFieldInputs,
  CustomFieldsSubCard,
} from 'mgr/actualslideout/components/view/DetailCard'
import { FeedbackRatingCard } from 'mgr/actualslideout/components/view/FeedbackRatingCard'
import { ReviewCard } from 'mgr/actualslideout/components/view/ReviewCard'
import { SpendCardTotals, SpendCardDetails, SpendCardItemHeader, SpendCardItemList } from 'mgr/actualslideout/components/view/SpendCard'
import { ItemUpgradeRow, UpgradesHeaderSubCard, UpgradesSubheader } from 'mgr/actualslideout/components/view/UpgradesCard'
import {
  CardBase,
  CardDivider,
  LastCardSection,
  SectionedCard,
  SpecialCardSection,
  StandardFieldText,
  ViewCardTitleSmall,
  ViewField,
} from 'mgr/actualslideout/components/view/ViewCommon'
import { FlexColumnContainer, FlexRowContainer } from 'mgr/actualslideout/components/view/ViewLayout'
import { eventNoOp, IconifiedLink } from 'mgr/actualslideout/components/view/ViewReservationHeader'
import CardAttachmentArea from 'mgr/actualslideout/containers/view/CardAttachmentArea'
import PaymentCard from 'mgr/actualslideout/containers/view/PaymentCard'
import { selectViewModeTimeSlotAvailableTableOptions } from 'mgr/actualslideout/selectors/BookSelectors'
import { selectShiftHumanName, selectStatusWithSpecialEntries } from 'mgr/actualslideout/selectors/ViewSelectors'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { getPerksList } from 'mgr/pages/single-venue/marketing/actions/Perks'
import { StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'
import DropdownMenu from 'svr/component-lib/Manager/Menus/DropdownMenu'
import { getDurationDisplay } from 'svr/common/TimeUtil'
import { PaylinkBanner } from 'mgr/actualslideout/components/view/PaylinkBanner'
import { ExcludeFromShiftPacingBanner } from '../../components/view/ExcludeFromShiftPacing'
import { useGetUpgradesQuery } from '@sevenrooms/core/api'
import { skipToken } from '@reduxjs/toolkit/query'
import { defaultUpgrades } from 'mgr/actualslideout/components/availability/defaults'

const OVERFLOW_CHOICES = _.map(ViewActualActions.OVERFLOW_MENU_ACTIONS, (v, k) => ({
  name: v,
  value: k,
}))
const CardText = styled.span`
  color: ${props => props.theme.navigationDark};
`
const MajorCardText = styled(CardText)`
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
`

const SpacedIcon = styled(StyledVmsIconXS)`
  margin-right: 16px;
  color: ${props => props.theme.navigationDark};
`

const SummaryIconRow = styled(FlexRowContainer)`
  padding: 8px 0px;
  color: ${props => props.theme.navigationDark};
`

function IconAndTextMajor({ testId, icon, text }) {
  return (
    <SummaryIconRow>
      <SpacedIcon>{icon}</SpacedIcon>
      <MajorCardText data-test={testId}>{text}</MajorCardText>
    </SummaryIconRow>
  )
}

const getRecommendText = recommendToFriend => {
  let text = 'not filled in'

  if (recommendToFriend === true) {
    text = 'Yes'
  } else if (recommendToFriend === false) {
    text = 'No'
  }

  return text
}

const TABLE_OPEN_PATH_INIT = ['init']
const TABLE_OPEN_PATH_CUSTOM = ['init', 'custom']

// While at first glance it may seem this card is
// ripe to be decomposed (and indeed it is for
// maintainability), pretty much everything relies
// on visibleActual which is completely replaced
// with each edit so there is no performance gain
// in Redux.
function CardDisplay({
  visibleActual,
  availableStatuses,
  availableTableOptions,
  selectedResTagsDisplay,
  canEditReservation,
  venueName,
  shiftHumanName,
  actions,
  customFieldsConfig,
  confirmationIncludeEndTime,
  sourceTags,
  sourceInitials,
  spendDetailsOpen,
  spendListOpen,
  isInService,
  hasPrepayment,
  hasPayments,
  showPerksField,
  hasVisibleCostOptions,
  selectedTableIds,
  isAutoAssign,
  isCustomAssign,
  feedbackDetailsOpen,
  reviews,
  canViewPosDataCheck,
  venueTimezone,
  venueId,
  perksList,
}) {
  useEffect(() => {
    if (venueId) {
      actions.getPerksList(venueId)
    }
  }, [venueId, actions])

  const { data: upgradesData = defaultUpgrades } = useGetUpgradesQuery(venueId ?? skipToken)
  const { inventories } = upgradesData

  if (!visibleActual) {
    return null
  }

  const showBuffers = window.globalInit.venueSettings.is_buffers_enabled && visibleActual.buffer_mins

  const tableOpenPath = isCustomAssign ? TABLE_OPEN_PATH_CUSTOM : TABLE_OPEN_PATH_INIT
  let tableDropdownValue = selectedTableIds
  if (!canEditReservation) {
    tableDropdownValue = [{ value: visibleActual.table_ids_list }]
  } else if (isAutoAssign) {
    tableDropdownValue = [{ value: 'auto' }]
  }
  const tableValue = isAutoAssign ? 'auto' : visibleActual.table_ids_list
  let tablePlaceholder = ''
  let disableTables = false
  if (!canEditReservation) {
    // No availability, so make disabled dropdown populate
    // eslint-disable-next-line no-param-reassign
    availableTableOptions = [
      {
        name: isAutoAssign ? 'Auto Assign' : visibleActual.table_codes_display,
        value: tableValue,
      },
    ]
  } else if (_.isEmpty(availableTableOptions) && !_.isEmpty(visibleActual.table_codes_display)) {
    // eslint-disable-next-line no-param-reassign
    availableTableOptions = [
      {
        name: visibleActual.table_codes_display,
        value: tableValue,
      },
    ]
    tablePlaceholder = isAutoAssign ? 'Auto Assigned' : visibleActual.table_codes_display
    disableTables = true
  }

  const freeFormPerks = visibleActual.perks_freeform
  const costOptionAmountFormatted = visibleActual.min_price_formatted
  const detailsEditHandler = () =>
    canEditReservation &&
    actions.enterEditReservation({
      actual: visibleActual,
      editStep: 'details',
    })

  const departureTime = confirmationIncludeEndTime && visibleActual.departure_time_display
  const customFieldInputs = buildCustomFieldInputs(
    customFieldsConfig,
    _.pick(visibleActual, [
      'custom_group_0',
      'custom_group_1',
      'custom_group_2',
      'custom_group_3',
      'custom_group_4',
      'custom_venue_0',
      'custom_venue_1',
      'custom_venue_2',
      'custom_venue_3',
      'custom_venue_4',
      'custom_unindexed',
    ])
  )
  const showPaylinkAutoCancelBanner =
    visibleActual.paylink_auto_cancel_datetime && moment.utc().isBefore(moment.utc(visibleActual.paylink_auto_cancel_datetime))
  const showExcludedFromShiftPacing =
    window.globalInit.venueSettings.exclude_from_shift_pacing_enabled && visibleActual.exclude_from_shift_pacing
  let accessRuleLink
  if (visibleActual.access_rule) {
    accessRuleLink =
      `/manager/${window.globalInit.venueUrlKey}/manage/capacity/accessrules` +
      `?selected_access_rule_id=${visibleActual.access_rule.id}&date=${visibleActual.date}`
  } else {
    accessRuleLink = null
  }

  const showUpgradesSection =
    (visibleActual.automatically_included_upsells && visibleActual.automatically_included_upsells.length > 0) ||
    (visibleActual.upsell_snapshot &&
      visibleActual.upsell_snapshot.selected_inventories &&
      Object.values(visibleActual.upsell_snapshot.selected_inventories).length > 0)

  return (
    <FlexColumnContainer style={{ height: '100%', padding: '0 4px' }}>
      <FlexRowContainer
        margin="0"
        style={{
          height: '64px',
          minHeight: '64px',
          margin: '0 -4px',
          marginBottom: '4px',
        }}
        alignItems="center"
      >
        <DropdownArrowsPicker
          name="STATUS"
          value={visibleActual.status}
          choices={availableStatuses}
          isLightTheme
          style={{
            width: 169,
            minWidth: 169,
            marginRight: 0,
            textTransform: 'capitalize',
          }}
          optionsContainerStyle={{
            width: 200,
            minWidth: 200,
          }}
          onChangeHandler={val => canEditReservation && actions.changeSelectedStatus(val)}
          disabled={!canEditReservation}
          borderAreaStyle={{
            height: 66,
            paddingTop: 0,
            borderRadius: 0,
            marginLeft: 0,
            marginRight: 0,
            paddingBottom: 0,
          }}
        />

        <DropdownMenu
          name="TABLE"
          selectedValues={tableDropdownValue}
          openPath={tableOpenPath}
          choices={availableTableOptions}
          isLightTheme
          useOutsideLabel={false}
          onChangeHandler={actions.changeSelectedTables}
          onNestedChangeHandler={updatedPath => actions.changeIsCustomAssign(updatedPath.indexOf('custom') > -1)}
          onCloseHandler={actions.saveSelectedTable}
          disabled={!canEditReservation || disableTables}
          placeholder={tablePlaceholder}
          optionsContainerStyle={{
            maxWidth: 400,
          }}
          style={{
            width: 169,
            minWidth: 169,
            marginRight: 0,
          }}
          borderAreaStyle={{
            height: 66,
            paddingTop: 0,
            borderRadius: 0,
            marginLeft: 0,
            marginRight: 0,
            paddingBottom: 0,
          }}
        />

        <DropdownArrowsPicker
          name=""
          value="..."
          icon={VmsIcons.More}
          choices={OVERFLOW_CHOICES}
          isLightTheme
          showNavigation={false}
          showCaret={false}
          onChangeHandler={actions.handleOverflowMenu}
          dontShowSelectedItem
          style={{
            width: 200,
            minWidth: 200,
            boxShadow: 'none',
          }}
          noHeader
          placeholder=""
          borderAreaStyle={{
            textOverflow: 'clip',
            height: 66,
            paddingLeft: 12,
            paddingRight: 12,
            width: 58,
            maxWidth: 58,
            paddingTop: 0,
            borderRadius: 0,
            margin: 0,
            paddingBottom: 0,
            textAlign: 'center',
          }}
        />
      </FlexRowContainer>

      <FlexColumnContainer id="reservation-cards" style={{ overflowY: 'auto', overflowX: 'hidden', height: 'auto' }}>
        {showPaylinkAutoCancelBanner && <PaylinkBanner autoCancelDatetime={visibleActual.paylink_auto_cancel_datetime} />}
        {showExcludedFromShiftPacing && <ExcludeFromShiftPacingBanner />}
        <SectionedCard
          data-test="sr-section-venue"
          canEditReservation={canEditReservation}
          onClick={() =>
            canEditReservation &&
            actions.enterEditReservation({
              actual: visibleActual,
              editStep: 'availability',
              forceNotGetLockOnLoad: true,
            })
          }
        >
          {venueName ? <IconAndTextMajor testId="sr-label-venue_name" icon={VmsIcons.Location} text={venueName} /> : null}
          <IconAndTextMajor
            testId="sr-label-reservation_date"
            icon={VmsIcons.Calendar}
            text={visibleActual.date_format_mostly_full_no_year}
          />
          <IconAndTextMajor
            testId="sr-label-reservation_time"
            icon={VmsIcons.Clock}
            text={`${visibleActual.arrival_time_display} ${departureTime ? '-' : ''} ${departureTime || ''}`}
          />
          <IconAndTextMajor testId="sr-label-party_size" icon={VmsIcons.Clients} text={visibleActual.max_guests} />
          <CardDivider />
          {visibleActual.finalSpend && <IconAndTextMajor icon={VmsIcons.AddReservation} text={visibleActual.finalSpend} />}
          {shiftHumanName ? <IconAndTextMajor icon={VmsIcons.Shift} text={shiftHumanName} testId="sr-label-shift" /> : null}
          {visibleActual.access_rule ? (
            <>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconAndTextMajor
                  icon={VmsIcons.AccessRule}
                  text={visibleActual.access_rule.public_time_slot_description || visibleActual.access_rule.name}
                  testId="sr-label-access-rule"
                />
                <a
                  onClick={eventNoOp}
                  href={accessRuleLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    position: 'relative',
                  }}
                  data-test="sr-access-rule-link"
                >
                  <IconifiedLink>
                    <StyledVmsIconXS>{VmsIcons.ExternalLink}</StyledVmsIconXS>
                  </IconifiedLink>
                </a>
              </div>
            </>
          ) : null}
          {visibleActual.duration ? (
            <IconAndTextMajor
              testId="sr-label-reservation_duration"
              icon={VmsIcons.Timer}
              text={
                showBuffers
                  ? `${getDurationDisplay(visibleActual.duration, false, false)} (+ ${visibleActual.buffer_mins} min)`
                  : getDurationDisplay(visibleActual.duration, false, false)
              }
            />
          ) : null}
          {visibleActual.venue_seating_area_name ? (
            <IconAndTextMajor icon={VmsIcons.Floorplan} text={visibleActual.venue_seating_area_name} />
          ) : null}
        </SectionedCard>

        {showUpgradesSection ? (
          <SectionedCard data-test="sr-section-upgrade" canEditReservation={false}>
            <ViewField>
              <UpgradesHeaderSubCard />
            </ViewField>
            <FlexRowContainer alignItems="flex-start">
              <FlexColumnContainer>
                {visibleActual.automatically_included_upsells &&
                  visibleActual.automatically_included_upsells.length > 0 &&
                  inventories.length > 0 && (
                    <>
                      <UpgradesSubheader text="bundled" />
                      {visibleActual.automatically_included_upsells
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(upgrade => (
                          <ItemUpgradeRow
                            upgradeItemCount={`${upgrade.quantity_num}x`}
                            upgradeItem={upgrade.name}
                            upgradePrice={
                              inventories.find(inventory => inventory.id === upgrade.id)?.isPriceHidden
                                ? null
                                : priceFormatter.format(upgrade.price * upgrade.quantity_num, {
                                    code: window.globalInit.venueCurrencyCode,
                                  })
                            }
                            key={upgrade.id}
                          />
                        ))}
                    </>
                  )}
                {visibleActual.upsell_snapshot &&
                  visibleActual.upsell_snapshot.selected_inventories &&
                  Object.values(visibleActual.upsell_snapshot.selected_inventories).length > 0 && (
                    <>
                      <UpgradesSubheader text="selected" />
                      {Object.values(visibleActual.upsell_snapshot.selected_inventories)
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(upgrade => (
                          <ItemUpgradeRow
                            upgradeItemCount={`${upgrade.quantity}x`}
                            upgradeItem={upgrade.name}
                            upgradePrice={
                              upgrade.is_price_hidden
                                ? null
                                : priceFormatter.format(upgrade.price * upgrade.quantity, {
                                    code: window.globalInit.venueCurrencyCode,
                                  })
                            }
                            key={upgrade.id}
                          />
                        ))}
                    </>
                  )}
              </FlexColumnContainer>
            </FlexRowContainer>
          </SectionedCard>
        ) : null}

        {isInService || hasPrepayment ? (
          <SectionedCard data-test="sr-section-spend" canEditReservation={false}>
            <ViewField>
              <ViewCardTitleSmall>Spend</ViewCardTitleSmall>
            </ViewField>

            <SpendCardTotals
              {...{
                spendDetailsOpen,
                toggleSpendDetails: actions.toggleSpendDetails,
              }}
              totalNet={visibleActual.final_bill_formatted}
              onsiteNet={
                visibleActual.pos_aggregate_items.hasCharges
                  ? visibleActual.pos_aggregate_items.subtotal
                  : visibleActual.onsite_payment_formatted
              }
              onsiteTaxPercentage={
                visibleActual.pos_aggregate_items.hasCharges
                  ? visibleActual.pos_aggregate_items.tax_percentage
                  : visibleActual.non_pos_onsite_tax_percentage_formatted
              }
              onsiteTaxAmount={
                visibleActual.pos_aggregate_items.hasCharges
                  ? visibleActual.pos_aggregate_items.tax
                  : visibleActual.onsite_payment_tax_formatted
              }
              onsiteTipPercentage={
                visibleActual.pos_aggregate_items.hasCharges
                  ? visibleActual.pos_aggregate_items.tip_percentage
                  : visibleActual.non_pos_onsite_gratuity_percentage_formatted
              }
              onsiteTipAmount={
                visibleActual.pos_aggregate_items.hasCharges
                  ? visibleActual.pos_aggregate_items.tip
                  : visibleActual.onsite_payment_gratuity_formatted
              }
              onsiteAdminFeePercentage={visibleActual.pos_aggregate_items.admin_fee_percentage}
              onsiteAdminFeeAmount={visibleActual.pos_aggregate_items.admin_fee}
              onsiteGross={
                visibleActual.pos_aggregate_items.hasCharges
                  ? visibleActual.pos_aggregate_items.total
                  : visibleActual.onsite_payment_total_formatted
              }
              prepaymentNet={visibleActual.prepayment_net_formatted}
              prepaymentServiceCharge={visibleActual.prepayment_service_charge_formatted}
              prepaymentTaxAmount={visibleActual.prepayment_tax_formatted}
              prepaymentTipAmount={visibleActual.prepayment_gratuity_formatted}
              prepaymentGross={visibleActual.prepayment_formatted}
            />

            {canViewPosDataCheck && spendDetailsOpen ? (
              <div>
                <SpendCardDetails
                  server={visibleActual.served_by_name || '-'}
                  checkNumbers={visibleActual.check_numbers || '-'}
                  paidBy={visibleActual.paid_by_name}
                />
                {visibleActual.pos_aggregate_items.hasCharges ? (
                  <div>
                    <SpendCardItemHeader
                      itemCount={visibleActual.pos_aggregate_items.items.reduce((accum, item) => accum + item.quantity, 0)}
                    />
                    <LastCardSection showBorder={false}>
                      <SpendCardItemList
                        {...{
                          spendListOpen,
                          items: visibleActual.pos_aggregate_items.items,
                          toggleSpendList: actions.toggleSpendList,
                        }}
                      />
                    </LastCardSection>
                  </div>
                ) : null}
              </div>
            ) : null}
          </SectionedCard>
        ) : null}

        <div id="review-card">
          {reviews.length ? (
            <SectionedCard canEditReservation={false}>
              <ViewField>
                <ViewCardTitleSmall>Review</ViewCardTitleSmall>
                {reviews.map((review, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ReviewCard review={review} venueName={venueName} unlink={actions.unlink} key={`review_${index}`} />
                ))}
              </ViewField>
            </SectionedCard>
          ) : null}
        </div>

        {!_.isEmpty(visibleActual.reservation_feedback) && !_.isEmpty(visibleActual.reservation_feedback.ratings) && (
          <SectionedCard>
            <CardBase canEditReservation={false}>
              <FeedbackRatingCard
                {...{
                  feedbackDetailsOpen,
                  toggleFeedbackDetails: actions.toggleFeedbackDetails,
                }}
                feedbackRatings={visibleActual.reservation_feedback.ratings}
                feedbackNotes={visibleActual.reservation_feedback.notes}
                feedbackRecommend={getRecommendText(visibleActual.reservation_feedback.recommend_to_friend)}
              />
            </CardBase>
          </SectionedCard>
        )}

        <SectionedCard data-test="sr-section-details" canEditReservation={false}>
          <SpecialCardSection isTopCard onClick={detailsEditHandler} isBottomCard={false} canEditReservation={canEditReservation}>
            <ViewField>
              <DetailsHeaderSubCard />
            </ViewField>
            <ViewField>
              <NoteSubCard editCallback={detailsEditHandler} noteContents={visibleActual.full_notes} autoTags={visibleActual.client_tags} />
            </ViewField>
            {(visibleActual.hotel_confirmation || visibleActual.hotel_room) && (
              <ViewField>
                {visibleActual.hotel_id && <StandardFieldText>Hotel ID: {visibleActual.hotel_id}</StandardFieldText>}
                {visibleActual.hotel_confirmation && (
                  <StandardFieldText>Confirmation #: {visibleActual.hotel_confirmation}</StandardFieldText>
                )}
                {visibleActual.hotel_room && <StandardFieldText>Room #: {visibleActual.hotel_room}</StandardFieldText>}
              </ViewField>
            )}
            <CardDivider />
            {hasVisibleCostOptions ? (
              <ViewField>
                <PriceSubCard editCallback={detailsEditHandler} costOptionAmountFormatted={costOptionAmountFormatted} />
              </ViewField>
            ) : null}
            <ViewField>
              <TagSubCard selectedTags={selectedResTagsDisplay} editCallback={detailsEditHandler} />
            </ViewField>

            {showPerksField && (
              <ViewField>
                <PerksSubCard
                  freeFormPerks={freeFormPerks}
                  editCallback={detailsEditHandler}
                  perksList={perksList}
                  autoTags={visibleActual.client_tags}
                  reservationTags={selectedResTagsDisplay}
                  startDateTime={visibleActual.date_arrival_time_dt_sync_dt_formatted}
                />
              </ViewField>
            )}
            <CardDivider />
          </SpecialCardSection>
          <SpecialCardSection isBottomCard hasTopMargin isTopCard={false} canEditReservation={canEditReservation}>
            <ViewField>
              <CardAttachmentArea />
            </ViewField>
          </SpecialCardSection>
        </SectionedCard>

        {!_.isEmpty(customFieldInputs) && (
          <SectionedCard canEditReservation={canEditReservation}>
            <CustomFieldsSubCard customFieldInputs={customFieldInputs} editCallback={detailsEditHandler} />
          </SectionedCard>
        )}

        <SectionedCard data-test="sr-section-contact" canEditReservation={canEditReservation}>
          <ClientSubCard
            editCallback={() =>
              canEditReservation &&
              actions.enterEditReservation({
                actual: visibleActual,
                editStep: 'client',
              })
            }
            phone={visibleActual.venue_group_client.phone_number_formatted}
            email={visibleActual.venue_group_client.email_address}
            phoneAlt={visibleActual.venue_group_client.phone_number_alt_formatted}
            emailAlt={visibleActual.venue_group_client.email_address_alt}
          />

          {visibleActual.source_client && (
            <SourceSubCard
              sourceName={visibleActual.source_client.name_only_display}
              sourceInitials={sourceInitials}
              sourcePhoto={visibleActual.source_client.photo_obj}
              sourceEmail={visibleActual.source_client.email_address}
              sourceTags={sourceTags}
              sourcePhone={visibleActual.source_client.phone_number_formatted}
              editCallback={() =>
                canEditReservation &&
                actions.enterEditReservation({
                  actual: visibleActual,
                  editStep: 'source',
                })
              }
            />
          )}
        </SectionedCard>

        {hasPayments ? <PaymentCard actual={visibleActual} /> : null}
      </FlexColumnContainer>
    </FlexColumnContainer>
  )
}

const mapStateToProps = state => {
  const { availableTableOptions, isCustomAssign, selectedTableIds } = selectViewModeTimeSlotAvailableTableOptions(state)
  return {
    visibleActual: state.viewResState.actual,
    availableTableOptions,
    selectedResTagsDisplay: state.viewResState.selectedResTagsDisplay,
    availableStatuses: selectStatusWithSpecialEntries(state),
    venueName: (state.viewResState.viewVenue || {}).internalDisplayName,
    customFieldsConfig: state.viewResState.viewVenue ? state.viewResState.viewVenue.customFieldsConfig.actual : [],
    selectedTableIds,
    isAutoAssign: state.viewResState.isAutoAssign,
    isCustomAssign,
    hasVisibleCostOptions: !_.isNil(state.viewResState.viewVenue) ? !_.isEmpty(state.viewResState.viewVenue.costOptions) : false,
    showPerksField: _.isNil(state.viewResState.viewVenue) ? false : state.viewResState.viewVenue.showPerksField,
    confirmationIncludeEndTime: (state.viewResState.viewVenue || {}).confirmationIncludeEndTime,
    canEditReservation: state.viewResState.canEditReservation,
    canViewPosDataCheck: state.bookClientState.initVenue.features.can_view_pos_check_data,
    shiftHumanName: selectShiftHumanName(state),
    sourceInitials: state.viewResState.sourceInitials,
    sourceTags: state.viewResState.sourceTags,
    spendDetailsOpen: state.viewResState.spendDetailsOpen,
    spendListOpen: state.viewResState.spendListOpen,
    isInService: state.viewResState.isInService,
    hasPrepayment: !!(state.viewResState.actual && state.viewResState.actual.prepayment_formatted),
    hasPayments: state.bookState.selectedVenue.bookSettings.paymentsEnabled,
    feedbackDetailsOpen: state.viewResState.feedbackDetailsOpen,
    reviews: state.viewResState.reviews,
    venueTimezone: state.bookPaymentState.venueTimezone,
    venueId: state.viewResState.actual?.venue_id,
    perksList: state.perksList.perks,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      handleOverflowMenu: ViewActualActions.forwardOverflowMenuAction,
      enterEditReservation: SlideoutActions.enterEditReservation,
      changeSelectedStatus: ViewActualActions.tryPutStatusAssignment,
      changeSelectedTables: ViewActualActions.changeSelectedTables,
      changeIsCustomAssign: ViewActualActions.changeIsCustomAssign,
      saveSelectedTable: ViewActualActions.tryPutTableAssignment,
      toggleSpendDetails: ViewActualActions.toggleSpendDetails,
      toggleSpendList: ViewActualActions.toggleSpendList,
      toggleFeedbackDetails: ViewActualActions.toggleFeedbackDetails,
      unlink: ReviewActions.tryUnlinkReview,
      getPerksList,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(CardDisplay)
